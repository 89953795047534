import { baseUnit } from '../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
export const createBlockStyle = (defaultBlocksWidths, paddingOverride = { largePadding: 1, mediumPadding: 1, smallPadding: 1 }) => {
    const { largeWidth, mediumWidth, smallWidth } = defaultBlocksWidths;
    const { largePadding, mediumPadding, smallPadding } = paddingOverride;
    return {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '100%',
        boxSizing: 'border-box',
        [MEDIA_BREAKPOINTS.large]: {
            width: `${largeWidth}px`,
            paddingRight: `${baseUnit * largePadding}px`,
            paddingLeft: `${baseUnit * largePadding}px`,
            scrollMarginTop: '100px',
        },
        [MEDIA_BREAKPOINTS.medium]: {
            width: `${mediumWidth}px`,
            paddingRight: `${baseUnit * mediumPadding}px`,
            paddingLeft: `${baseUnit * mediumPadding}px`,
            scrollMarginTop: '100px',
        },
        [MEDIA_BREAKPOINTS.small]: {
            width: `${smallWidth}px`,
            paddingRight: `${baseUnit * smallPadding}px`,
            paddingLeft: `${baseUnit * smallPadding}px`,
            scrollMarginTop: '20px',
        },
    };
};
