import React from 'react';
import { useSelector } from 'react-redux';
import {
  Ad,
  AppContent,
  ArticleContent,
  ArticleOnTopTitle,
  ContentWithoutSidebar,
  ErrorBoundary,
  HoverAuthorsWithRoundImage,
  HUGE_SCREEN_SIZE,
  Intro,
  ManualPostsRecommendation,
  PostWithoutSidebarMainContent,
  SafeArticleContentWrapper,
  SecondarySectionTitle,
  SectionHeader,
  SectionWithTitle,
  Slideshow,
  ArticleAd,
} from 'mm-ui-components';
import { Store } from 'redux';
import { StyleSheet } from 'aphrodite/no-important';
import { createBlockStyle } from 'mm-ui-components/dist/src/base/articleComponents/blockMutualStyle';
import { semanticCSS } from 'mm-ui-components/dist/src/typography/semanticTags';
import { Theme } from '../../components/connectedComponents/Theme';
import { PostMetaTags } from '../../components/connectedComponents/PostMetaTags';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { Page } from '../Page';
import {
  getAuthors,
  getBody,
  getCover,
  getCreatedAtDateFormatted,
  getCreatedAt,
  getUpdatedAt,
  getCreatedAtTimeAndDateFormatted,
  getDisclaimerText,
  getImageCover,
  getMetadataDescription,
  getNoIndex,
  getOgDescription,
  getOgImage,
  getOgTitle,
  getRecommendationsArticles,
  getRecommendationsTitle,
  getSeoDescription,
  getSeoTitle,
  getShareConfig,
  getShowUpdatedAt,
  getSlideshowData,
  getSponsor,
  getTitle,
  getTwitterDescription,
  getTwitterImage,
  getTwitterTitle,
  getUpdatedAtDateFormatted,
  getUpdatedAtTimeAndDateFormatted,
  getUpdatedText,
  getShouldDisplayDisclaimerAtBottom,
  getTags,
  getFaqData,
  getIntro,
  getCommercialTags,
  getOwnerUsername,
} from '../../store/template/postPage/postPageKyiv/postPageKyiv.selectors';
import {
  getAdSlotId,
  getAdSlotOnSuccess,
  getCanonicalUrl,
  getExperiments,
  getIsMobileViewer,
  getResourceID,
  getSiteName,
  getTemplateName,
} from '../../store/config/config.selectors';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';

import { createBlocksWidth } from '../postPage.util';
import { SLOT_NAMES } from '../templates.const';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import { DisclaimersAtDynamicPosition } from '../../components/disclaimer/DisclaimersAtDynamicPosition';
import { AdditionalStyleSheet } from '../templates.utils';

interface PostPageProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

const createStyle = () => StyleSheet.create({
  style: {
    margin: '0 auto',
  },
}) as AdditionalStyleSheet;

export const PostPageComponent: React.FunctionComponent<PostPageProps> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <PostPageContentConnected />
      </ErrorBoundary>
    </Page>
  );
};

export const PostPageContentConnected: React.FunctionComponent = () => {
  const oneOnOneId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
  const oneOnOneOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE));
  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const title = useSelector(getTitle);
  const intro = useSelector(getIntro);
  const updatedText = useSelector(getUpdatedText);
  const cover = useSelector(getCover);
  const body = useSelector(getBody);
  const shareConfig = useSelector(getShareConfig);
  const canonical = useSelector(getCanonicalUrl);
  const templateName = useSelector(getTemplateName);
  const siteName = useSelector(getSiteName);
  const sponsor = useSelector(getSponsor);
  const recommendationsSectionTitle = useSelector(getRecommendationsTitle);
  const recommendationsSectionArticles = useSelector(getRecommendationsArticles);
  const authors = useSelector(getAuthors);
  const createdAtDateFormatted = useSelector(getCreatedAtDateFormatted);
  const createdAtTimeAndDateFormatted = useSelector(getCreatedAtTimeAndDateFormatted);
  const updatedAtDateFormatted = useSelector(getUpdatedAtDateFormatted);
  const updatedAtTimeAndDateFormatted = useSelector(getUpdatedAtTimeAndDateFormatted);
  const slideshow = useSelector(getSlideshowData);
  const pageDescription = useSelector(getMetadataDescription);
  const seoTitle = useSelector(getSeoTitle) || title;
  const pageImage = useSelector(getImageCover);
  const createdAt = useSelector(getCreatedAt);
  const updatedAt = useSelector(getUpdatedAt);
  const showUpdatedAt = useSelector(getShowUpdatedAt);
  const noIndex = useSelector(getNoIndex);
  const ogDescription = useSelector(getOgDescription);
  const ogTitle = useSelector(getOgTitle);
  const ogImage = useSelector(getOgImage);
  const twitterDescription = useSelector(getTwitterDescription);
  const twitterTitle = useSelector(getTwitterTitle);
  const twitterImage = useSelector(getTwitterImage);
  const seoDescription = useSelector(getSeoDescription);
  const disclaimerText = useSelector(getDisclaimerText);
  const tags = useSelector(getTags);
  const shouldDisplayDisclaimerAtBottom = useSelector(getShouldDisplayDisclaimerAtBottom);
  const faqData = useSelector(getFaqData);
  const experiments = useSelector(getExperiments);
  const commercialTags = useSelector(getCommercialTags);
  const ownerUsername = useSelector(getOwnerUsername);
  const resourceID = useSelector(getResourceID);
  const isMobileViewer = useSelector(getIsMobileViewer);
  const articleSection = '';
  const additionalStyle = createStyle();
  const defaultBlocksWidths = createBlocksWidth(740, 680, 640);
  const overrideBlocksWidths = {
    image: createBlocksWidth(880, 1080, 680),
  };
  const getIntroStyle = () => {
    return StyleSheet.create({
      style: {
        marginTop: '20px',
        ...createBlockStyle(defaultBlocksWidths),
      },
    }).style;
  };
  const slideshowBlockWidth = createBlocksWidth(920, 680, 640);

  const overrideBlockPadding = { largePadding: 0, mediumPadding: 0, smallPadding: 0 };
  const shouldShowRecommendationsSection = recommendationsSectionArticles && recommendationsSectionArticles.length > 0;
  const articleContentWrapperHeight = authors ? 10 * authors.length : 0;

  const hasSlideshow = (slideshow && slideshow.totalSlides > 0) || false;
  const authorMarginTop = hasSlideshow ? 1 : undefined;
  return (
    <React.Fragment>
      <PostMetaTags {...{
        pageDescription,
        seoTitle,
        pageImage,
        createdAtISO: createdAt,
        updatedAtISO: updatedAt,
        noIndex,
        ogDescription,
        ogTitle,
        ogImage,
        twitterDescription,
        twitterTitle,
        twitterImage,
        seoDescription,
        articleSection,
        authors,
        prevSlideshowUrl: slideshow && slideshow.prevLink,
        nextSlideshowUrl: slideshow && slideshow.nextLink,
        tags,
        faqData,
      }}
      />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainArticleContent">
            <ContentWithoutSidebar maxWidth={HUGE_SCREEN_SIZE} mediumWidth={880}>
              <PostWithoutSidebarMainContent>
                <ArticleOnTopTitle
                  {...cover}
                  title={title}
                  siteName={siteName}
                  templateName={templateName}
                  shareConfig={shareConfig}
                  canonical={canonical}
                  sponsor={sponsor}
                />
                {intro ? <Intro html={intro} styles={semanticCSS(getIntroStyle())} /> : null}
                <SafeArticleContentWrapper minHeightFactor={articleContentWrapperHeight}>
                  <Slideshow {...slideshow} blockWidths={slideshowBlockWidth} experiments={experiments} commercialTags={commercialTags} ownerUsername={ownerUsername} resourceID={resourceID}>
                    <DisclaimersAtDynamicPosition
                      disclaimerText={disclaimerText}
                      defaultBlocksWidths={defaultBlocksWidths}
                      shouldDisplayDisclaimerAtBottom={shouldDisplayDisclaimerAtBottom}
                    >
                      <ArticleContent
                        body={body}
                        templateName={templateName}
                        siteName={siteName}
                        defaultBlocksWidths={defaultBlocksWidths}
                        overrideBlocksWidths={overrideBlocksWidths}
                        blocksPaddingOverride={overrideBlockPadding}
                        imageWidthSmall={360}
                        imageWidthMedium={720}
                        imageWidthLarge={1080}
                        totalSlides={slideshow.totalSlides}
                        experiments={experiments}
                        commercialTags={commercialTags}
                        ownerUsername={ownerUsername}
                        isMobileViewer={isMobileViewer}
                      >
                        <HoverAuthorsWithRoundImage
                          authors={authors}
                          updatedText={updatedText}
                          createdDateFormatted={createdAtDateFormatted}
                          createdTimeAndDateFormatted={createdAtTimeAndDateFormatted}
                          createdAtISO={createdAt}
                          updatedDateFormatted={updatedAtDateFormatted}
                          updatedTimeAndDateFormatted={updatedAtTimeAndDateFormatted}
                          updatedAtISO={createdAt}
                          showUpdatedAt={showUpdatedAt}
                          marginTopFactor={authorMarginTop}
                        />
                        <ArticleAd
                          id={topAdId}
                          dataId=""
                          additionalStyle={{ style: { marginTop: '20px' } }}
                          defaultBlocksWidths={createBlocksWidth(970, 680, 640)}
                        />
                      </ArticleContent>
                    </DisclaimersAtDynamicPosition>
                  </Slideshow>
                </SafeArticleContentWrapper>
              </PostWithoutSidebarMainContent>
              {shouldShowRecommendationsSection && recommendationsSectionArticles ? (
                <ErrorBoundary>
                  <SectionWithTitle additionalStyle={additionalStyle}>
                    <SectionHeader moreButtonSvg={null} moreButtonText={null}>
                      <SecondarySectionTitle>{recommendationsSectionTitle}</SecondarySectionTitle>
                    </SectionHeader>
                    <ManualPostsRecommendation cards={recommendationsSectionArticles} />
                  </SectionWithTitle>
                </ErrorBoundary>
              ) : null}
            </ContentWithoutSidebar>
            <Ad id={oneOnOneId} onSuccess={oneOnOneOnSuccess} />
          </NavigationLayout>
        </AppContent>
      </Theme>
    </React.Fragment>
  );
};
