import { configReducer } from '../../store/config/config.reducer';
import { postPageReducer } from '../../store/template/postPage/postPage.reducer';
import {
  getArticleId,
  getBlockNames,
  getChannels,
  getCommercialTags,
  getContentTypesToQuantity,
  getCover,
  getCreatedAt,
  getUpdatedAt,
  getDfpCategory,
  getNumberOfWordsTyped,
  getOwnerName,
  getOwnerUsername,
  getTags,
  getTitle,
  getSlideshowData,
  getBreadCrumbs,
  getAuthorsUsernames,
  getTopic,
  getIntro,
  getSeoTitle,
} from '../../store/template/postPage/postPageKyiv/postPageKyiv.selectors';
import { PostPageComponent } from './PostPageKyivComponent';
import { TemplateRegistration } from '../templateRegistration';
import { AppState } from '../../store/initialStateRegistration';
import {
  GET_PAGE_DATA,
  GET_PALETTE_DATA,
  INJECT_ACCESSIBILITY_ICON_TO_HEADER,
  INJECT_ADS,
  INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY,
  INJECT_BUTTON_TO_FOOTER_LINKS_SECTION,
  INJECT_BUTTON_TO_MENU,
  INJECT_VIDEO_LOGO,
} from '../../client/clientAPI/events';
import { formatDate, getCountryCode, getCoverType } from '../templates.utils';
import { announcementReducer } from '../../store/announcement/announcement.reducer';
import { getAnalyticsCoreParamsForAllPages } from '../utils/getAnalyticsCoreParams';
import { headerReducer } from '../../store/navigations/navigation/header/header.reducer';
import { menuReducer } from '../../store/navigations/navigation/menu/menu.reducer';
import { footerReducer } from '../../store/navigations/navigation/footer/footer.reducer';

export const rootReducer = {
  config: configReducer,
  footer: footerReducer,
  header: headerReducer,
  menu: menuReducer,
  template: postPageReducer,
  siteAnnouncement: announcementReducer,
};

const getCoreParams = (state: AppState) => ({
  ...getAnalyticsCoreParamsForAllPages(state),
  articleId: getArticleId(state),
  articleTemplate: getBlockNames(state),
  channels: getChannels(state),
  articleAuthor: getOwnerName(state),
  ownerUsername: getOwnerUsername(state),
  authorsUsernames: getAuthorsUsernames(state),
  articleTitle: getTitle(state),
  articleIntro: !!getIntro(state) && getIntro(state)?.length !== 0,
  articlePublicationTime: formatDate(getCreatedAt(state)),
  contentTags: getTags(state),
  mainMedia: getCoverType(getCover(state)),
  countryCode: getCountryCode(),
  commercialTags: getCommercialTags(state),
  dfpCategory: getDfpCategory(state),
  articleContentCount: getContentTypesToQuantity(state),
  articleWordCount: getNumberOfWordsTyped(state),
  createdAt: getCreatedAt(state),
  updatedAt: getUpdatedAt(state),
  isSlideshow: !!getSlideshowData(state).totalSlides,
  mainCategoryEmbed: !!(getBreadCrumbs(state)?.categoryLink && getBreadCrumbs(state)?.isActive),
  topic: getTopic(state),
  seoTitle: getSeoTitle(state),
});

export const PostPageKyiv = new TemplateRegistration()
  .setComponent(PostPageComponent)
  .setRootReducer(rootReducer)
  .setCoreParams(getCoreParams)
  .addClientAPIEvent(INJECT_VIDEO_LOGO)
  .addClientAPIEvent(GET_PAGE_DATA)
  .addClientAPIEvent(INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY)
  .addClientAPIEvent(INJECT_BUTTON_TO_FOOTER_LINKS_SECTION)
  .addClientAPIEvent(INJECT_BUTTON_TO_MENU)
  .addClientAPIEvent(GET_PALETTE_DATA)
  .addClientAPIEvent(INJECT_ACCESSIBILITY_ICON_TO_HEADER)
  .addClientAPIEvent(INJECT_ADS)
  .build();
